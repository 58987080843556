<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-copy"/> {{caption}}
        <div class="card-header-actions">
          <CButton :color="filters ? 'dark' : 'warning'" size="sm" @click="toggleFilters" style="height: 26px; padding: 2px 5px;"><CIcon name="cil-filter"/></CButton>
          <CButton color="info" size="sm" @click="initNote" v-if="has_access">Nueva Nota de Crédito</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <CRow v-if="filters">
              <CCol md="5">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Cliente:</label>
                  <CSelect
                    :value.sync="customerSelect" 
                    :options="customersOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>
              <CCol md="5">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Fecha:</label>
                  <v-date-picker                    
                    v-model="range"    
                    class="date-picker"
                    locale="es-MX" size="sm"          
                    mode="range"
                  />        
                </div>
              </CCol>    
              <CCol md="2">
                <div role="group" class="form-group" style="margin-top: 25px">
                  <CButton style="margin-right:10px" color="success" size="sm"  @click="downloadExcel()">Exportar</CButton>
                  <CButton color="dark" size="sm"  @click="downloadZip()">PDFs y XML</CButton>
                </div>
              </CCol>              
          </CRow>     
          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('active')" class="nav-item">
              <a class="nav-link" :class="tab == 'active' ? 'active' : ''" aria-current="page">Vigentes <span v-show="totals.active >= 0">({{totals.active}})</span></a>
            </li>
            <li @click="changeTab('canceled')" class="nav-item">
              <a class="nav-link" :class="tab == 'canceled' ? 'active' : ''">Canceladas <span v-show="totals.canceled >= 0">({{totals.canceled}})</span></a>
            </li>
          </ul>    
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 30 : 10"
            :dark="dark"
            pagination
            class="listing"
          >
            <template #date="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>
            <template #folio="{item}"><td class="w150 text-center">{{ item.serial+item.folio }}</td></template>
            <template #subtotal="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.subtotal | currency }}</td></template>
            <template #transfered_taxes="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.transfered_taxes | currency }}</td></template>
            <template #withheld_taxes="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.withheld_taxes | currency }}</td></template>
            <template #total="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.total | currency }}</td></template>
            <template #customer_name="{item}"><td class="text-lefy"><span style="font-weight: bold;">{{item.receptor}}</span><br>{{item.customer_name}}</td></template>
            <template #actions="{item}">
              <td class="table_actions b3">
                <table style="padding: 0px; margin: auto; ">
                 <tr>
                  <td style="padding: 0px; border: 0px !important;">
                    <CButton color="info" size="sm" @click="viewNote(item)">Ver Nota</CButton>
                  </td>
                  <td style="padding: 0px; border: 0px !important;">
                    <CDropdown color="info" togglerText="" size="sm">
                      <CDropdownItem href="#" @click="viewNote(item)">Ver Nota</CDropdownItem>
                      <CDropdownDivider/>
                      <CDropdownItem href="#" v-if="item.cfdi_id > 0"  @click="downloadXML(item.id)">Descargar XML</CDropdownItem>
                      <CDropdownItem href="#" @click="downloadPDF(item.id)">Descargar PDF</CDropdownItem>
                    </CDropdown>
                  </td>
                  </tr>
                </table>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/credit-notes';
import customers_ws from '../../services/customers';
import store from '../../store'
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

moment.tz.setDefault('America/Mexico_city');

export default {
  name: 'CreditNotesTable',
  components: {'v-date-picker': DatePicker },
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [
        {key: 'date', label: 'Fecha'},
        {key: 'folio', label: 'Folio'},
        {key: 'customer_name', label: 'Cliente'},
        {key: 'subtotal', label: 'Subtotal'},
        {key: 'transfered_taxes', label: 'Trasladados'},
        {key: 'withheld_taxes', label: 'Retenidos'},
        {key: 'total', label: 'Total'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      tab: 'active',
      totals: {
        active: -1,
        canceled: -1
      },
      filters: false,
      customers: [],
      customerSelect: "",
      customersOptions: [],
      range: {
        start: new moment().startOf('month').toDate(),
        end: new moment().endOf("month").toDate(),
      },
    }
  },
  mounted: async function() {
    this.loading();

    this.getInitialTotals();

    let filters = this.getFilters();

    await this.getTotals(filters);  
    let response1 = await customers_ws.get(); 

    if(response1.type == "success"){
      this.customers = response1.data;

      this.customersOptions = await this.$parseSelectOptionsOrdered(this.customers, "id", "rfc:name",[{"label": "Todos","value":""}]);
    }

    this.$emit("refresh", "active", filters);

    this.loaded();
  },
  methods: {
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
        let filters = this.getFilters();
        this.$emit("refresh", tab,filters );
      }
    },
    toggleFilters () {
      this.filters = !this.filters;
    },
    getFilters(){
      let filters = {
        date : [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")],
        customer_id : this.customerSelect,
      };

      return filters;
    },
    async getInitialTotals(){
      let filters = {
        date : [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")],
        customer_id : this.customerSelect
      };

      let response = await ws.getTotals(filters);

      if(response.type == "success"){
        if(response.data.active < 100){
          this.range = {
            start: new moment().subtract(3, 'months').startOf('month').toDate(),
            end: new moment().endOf("month").toDate(),
          }
        }
      }
    },
    async getTotals(filters){
      let response = await ws.getTotals(filters);
      if(response.type == "success"){
        this.totals.active = response.data.active;
        this.totals.canceled = response.data.canceled + response.data.process_canceled;
      }
    },
    async changeFilters () {
      let filters = this.getFilters();
      await this.getTotals(filters)
      this.$emit("refresh", this.tab,filters );
    },  
    initNote () {
      window.location.href = "/#/credit-notes/draft";
    },
    viewNote (item) {
      window.location.href = "/#/credit-notes/"+item.id+"/view";
    },
    async downloadXML(id){
      ws.downloadXML(id);
    },
    async downloadPDF(id){
      ws.downloadPDF(id);
    },
    async downloadExcel(){
      this.loading();

      let filters = this.getFilters();
      filters.type = this.tab;
      ws.downloadExcel(filters);

      this.loaded();
    },
    async downloadZip(){
      this.loading();

      let filters = this.getFilters();
      filters.type = this.tab;
      ws.downloadZip(filters);

      this.loaded();
    },    
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
